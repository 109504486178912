import { HeadingSection } from "../HeadingSection"
import React, { useEffect } from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { useMediaQuery } from "@chakra-ui/react"

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import Link from "next/link"
import Image from "next/image"

export function NossasCategorias({ productsCategory }) {
  const [visibleSlides, setVisibleSlides] = React.useState(7)
  const [isDesktop] = useMediaQuery("(min-width: 1024px)")
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [isTablet] = useMediaQuery("(min-width: 768px)")

  useEffect(() => {
    setVisibleSlides(isDesktop ? 7 : isTablet ? 2 : isMobile ? 2 : 7)
  }, [isDesktop, isTablet, isMobile])

  return (
    <section className="flex flex-col gap-6 w-full">
      <HeadingSection text="Nossas categorias" />

      <CarouselProvider
        naturalSlideWidth={158}
        naturalSlideHeight={158}
        totalSlides={productsCategory?.length}
        step={1}
        visibleSlides={visibleSlides}
        isIntrinsicHeight={true}
        isPlaying
        infinite={true}
        className="relative"
      >
        <Slider>
          {/* Slide 1 */}

          {productsCategory.map((category, index) => (
            <Slide key={index} index={index}>
              <Link href={`/categoria/${category.link_categoria}`}>
                <div className="flex mx-auto flex-col items-center justify-center max-w-[158px] gap-2 cursor-pointer">
                  {/* <div className="max-w-[158px] w-full h-[158px] bg-gray-100 shadow-md border" /> */}
                  <Image
                    src={
                      category.imagem_categoria
                        ? category.imagem_categoria
                        : "https://imgproductioncrm.s3.us-east-2.amazonaws.com/produto%20carregada.png"
                    }
                    alt={category.titulo_categoria}
                    width={158}
                    height={158}
                    objectFit="cover"
                    loading="lazy"
                  />
                  <h2 className="font-bold text-center max-w-[158px]">{category.titulo_categoria}</h2>
                  <h3 className="text-sm text-center line-clamp-3">{category.descricao_categoria}</h3>
                </div>
              </Link>
            </Slide>
          ))}
        </Slider>

        <>
          <ButtonBack className="absolute left-0 border top-[30%] transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
            <IoIosArrowBack className="text-2xl" />
          </ButtonBack>
          <ButtonNext className="absolute right-0 border top-[30%] transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
            <IoIosArrowForward className="text-2xl" />
          </ButtonNext>
        </>
      </CarouselProvider>
    </section>
  )
}
